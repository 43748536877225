/* login.css */

* {
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0 5px 0 5px;
}
/* General container styling */
.login-container {
  display: flex;
  height: 100vh; /* Full screen height */
  font-family: Arial, sans-serif;
  overflow-y: hidden;
  padding: 0px;
}

/* Left Panel */
.login-left-panel {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 16px;
}

/* Overlay for DManager text */
.overlay {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.dmanager-title {
  font-size: 2rem;
  color: white;
  font-weight: bold;
  text-align: center;
}

/* Background Image */
.login-illustration {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures the image fits without cropping */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #00a36c;
}

/* Right Panel */
.login-right-panel {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  overflow-y: hidden;
  border-radius: 16px;
}

.login-form-container {
  max-width: 400px;
  text-align: center;
}

.login-form-container h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.login-form-container p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px; 
}
.divider {
  position: relative;
  margin: 20px 0;
  text-align: center;
  font-size: 0.9rem;
  color: #999;
}

.divider::before,
.divider::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 40%;
  height: 1px;
  background: #ccc;
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.divider span {
  background: #f4f4f4; /* Ensures the background doesn't overlap with the divider lines */
  padding: 0 20px; /* Adds horizontal padding around the text */
  position: relative;
  z-index: 1;
}

.login-btn{
  display: flex;
  justify-content: center;
}